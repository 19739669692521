import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { css } from '@emotion/core';

import { Block, TouchWrapper, Image, Text } from 'components/atoms';
import { StampCard, MyCardRewardSection } from 'components/molecules';
import { navigate } from 'gatsby';

// REDUX
import { selectAuthData } from 'modules/auth/selectors';
import { connect } from 'react-redux';

// HOC
import withTheme from 'hocs/withTheme';

const cardWrapperCss = css({
  '.stamp-card': {
    marginTop: 16
  }
});

class MyCardsPage extends React.Component {
  render() {
    const { shouldRenderLoading, renderLoading, profile } = this.props;
    return (
      <Block.Flex
        maxWidth={1200}
        mx={'auto'}
        flexDirection={'column'}
        alignItems={'center'}>
        {shouldRenderLoading && renderLoading()}
        {profile && (!profile.cards || !profile.cards.length) ? (
          <Block.MiddleCenter height={'100%'} flexDirection={'column'}>
            <Image
              height={200}
              opacity={0.5}
              src={
                'https://firebasestorage.googleapis.com/v0/b/mi-crm-pumpkin.appspot.com/o/pumpkin-hooray.png?alt=media&token=a0b8caa1-2a12-4d89-bca9-8506a0f03ca7'
              }
            />
            <Text mt={24} color={'lightGrey'}>
              เริ่มสะสมแต้มกับร้านโปรดได้เลย
            </Text>
          </Block.MiddleCenter>
        ) : (
          profile &&
          profile.cards.map(userCard => {
            const {
              id,
              cover,
              logo,
              gradientColor,
              name,
              description,
              textColor,
              primaryColor
            } = get(userCard, 'brand', {});
            return (
              <Block.Flex my={15} width={'100%'} justifyContent={'center'}>
                <Block maxWidth={425} mx={'5%'}>
                  <TouchWrapper
                    key={id}
                    onClick={() => navigate(`/brand/?brand=${id}`)}
                    className={cardWrapperCss}>
                    <StampCard
                      cover={cover}
                      logo={logo}
                      gradientColor={gradientColor}
                      name={name}
                      description={description}
                      textColor={textColor}
                      point={userCard.point}
                    />
                  </TouchWrapper>
                </Block>
                <Block display={['none', 'block']} width={[0, '50%', 470]}>
                  <MyCardRewardSection brandId={id} color={primaryColor} />
                </Block>
              </Block.Flex>
            );
          })
        )}
      </Block.Flex>
    );
  }
}

MyCardsPage.propTypes = {
  profile: PropTypes.arrayOf(PropTypes.shape({}))
};

// const EnhancedMyCards = compose(withAuthProtection(), withProfile())(
//   MyCardsPage
// );

const mapStateToProps = state => ({
  profile: selectAuthData(state)
});

export default connect(mapStateToProps, null)(withTheme()(MyCardsPage));
